<template>
    <Page class="profile">
        <template v-slot:title>
            Профиль
        </template>
        <div class="profile__block">
            <h1 class="profile__title">
                <span>
                    Личные данные
                    <router-link
                        class="icon-edit"
                        :to="{
                            name: 'PersonalAreaEdit'
                        }"
                    ></router-link>
                </span>
            </h1>
            <ul class="profile__data">
                <li>
                    <p class="profile__data-title">
                        Телефон
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.phone) | mask('+# ### ###-##-##') }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Email
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.email) }}
                    </p>
                </li>
            </ul>
            <ul class="profile__data">
                <li>
                    <p class="profile__data-title">
                        Фамилия
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.lastname) }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Имя
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.firstname) }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Отчество
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.patronymic) }}
                    </p>
                </li>
            </ul>
            <ul class="profile__data">
                <li>
                    <p class="profile__data-title">
                        Дата рождения
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.birthday) }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Пол
                    </p>
                    <p class="profile__data-value">
                        {{ userGender }}
                    </p>
                </li>
            </ul>
            <h1 class="profile__title">
                <span>
                    Паспортные данные
                    <router-link
                        class="icon-edit"
                        :to="{
                            name: 'PersonalAreaEdit'
                        }"
                    ></router-link>
                </span>
            </h1>
            <ul class="profile__data">
                <li>
                    <p class="profile__data-title">
                        Серия
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.passportData.passportseries) }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Номер
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.passportData.passportnumber) }}
                    </p>
                </li>
                <li>
                    <p class="profile__data-title">
                        Код подразд.
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.passportData.passportissuecode) }}
                    </p>
                </li>
            </ul>
            <ul class="profile__data">
                <li>
                    <p class="profile__data-title">
                        Город
                    </p>
                    <p class="profile__data-value">
                        {{ getValue(data.contactData.addrcity) }}
                    </p>
                </li>
            </ul>
        </div>
    </Page>
</template>

<script>
import './profile.scss'
import { mapGetters } from 'vuex'
import contacts from '@/mixins/contacts'

import Page from '@/applications/personal-area/layouts/page/Page'

import mask from '@/helpers/string/setMask'

export default {
    name: 'Profile',
    mixins: [
        contacts
    ],
    computed: {
        ...mapGetters({
            data: 'application/user',
        }),
        documents() {
            return this.$DICTIONARY.profileDocuments
        },
        userGender() {
            return +this.data.contactData.gender ? 'Женский' : 'Мужской'
        }
    },
    methods: {
        getValue(value) {
            return value || '-'
        }
    },
    components: {
        Page,
    },
    filters: {
        mask
    }
}
</script>